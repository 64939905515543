// AboutUs.js
import React from "react";
import { Link } from "react-router-dom";
import EarthCanvas from "../components/EarthCanvas";
import { ChevronRight } from "lucide-react";

const AboutUs = () => {
  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold bg-gradient-to-r from-[#359381] to-[#359381] bg-clip-text text-transparent mb-6 text-center">
          About Us
        </h2>
        <p className="text-lg text-gray-600 text-center mb-4">
          We started with a simple observation: the gap between environmental
          awareness and action isn't about lack of concern – it's about
          accessibility and understanding.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          {/* 3D Model Section */}
          <div className="w-full h-96">
            <EarthCanvas />
          </div>

          {/* Mission and Vision Section */}
          <div>
            <h3 className="text-2xl font-bold mb-4 text-[#003329]">
              Our Mission
            </h3>
            <p className="text-gray-600 mb-6 leading-relaxed">
              At Carbon2Capital, we are dedicated to empowering individuals and
              businesses to take actionable steps toward sustainability. By
              leveraging technology and data-driven insights, our mission is to
              make a measurable impact in reducing carbon emissions and
              fostering a greener future.
            </p>

            <h3 className="text-2xl font-bold mb-4 text-[#003329]">
              Our Vision
            </h3>
            <p className="text-gray-600 leading-relaxed">
              We envision a world where sustainability is accessible and
              achievable for everyone. Our platform connects users with
              innovative solutions to offset their carbon footprint while
              supporting projects that promote environmental well-being and
              economic growth.
            </p>
          </div>
        </div>

        {/* Core Values Section */}
        <div className="mt-6">
          <h3 className="text-2xl font-bold text-center mb-6 text-[#359381]">
            Our Core Values
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h4 className="text-xl font-bold mb-4 text-[#003329]">
                Sustainability
              </h4>
              <p className="text-gray-600 leading-relaxed">
                For us, sustainability isn't just a goal – it's our foundation.
                Every solution we offer is designed to create a lasting,
                meaningful impact on our planet's health. We're committed to
                making eco-friendly practices both understandable and
                achievable.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h4 className="text-xl font-bold mb-4 text-[#003329]">
                Knowledge
              </h4>
              <p className="text-gray-600 leading-relaxed">
                We believe everyone deserves to understand the environmental
                impact of their choices. We don't expect people to change just
                because something has higher emissions – but we do care if they
                don't know why it matters. Our role isn't to dictate choices but
                to ensure they're made with complete awareness. True autonomy
                comes from informed decisions, which we're here to provide.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h4 className="text-xl font-bold mb-4 text-[#003329]">
                Innovation
              </h4>
              <p className="text-gray-600 leading-relaxed">
                InnovationI isn't just about new technology. While we embrace
                cutting-edge solutions, we believe true innovation comes from
                finding unexpected answers in familiar places and adapting what
                works across different sectors. This mindset helps us deliver
                personalized solutions that make reducing your carbon footprint
                feel less like a challenge and more like an opportunity.
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md">
              <h4 className="text-xl font-bold mb-4 text-[#003329]">
                Community
              </h4>
              <p className="text-gray-600 leading-relaxed">
                We believe in the power of collective action. One person
                changing everything might have less impact than a group of
                people making small changes – but doing it knowingly and
                willingly. We build tools that make it easy for people to
                understand their environmental impact and share their journey
                with others. Because when knowledge spreads, impact multiplies.
              </p>
            </div>
          </div>
        </div>

        {/* Call to Action Section */}
        <div className="mt-16 text-center">
          <h3 className="text-2xl font-bold mb-4 text-[#359381]">
            Join Us on Our Journey
          </h3>
          <p className="text-lg text-gray-600 mb-8">
            Together, we can create a meaningful impact. Whether you are an
            individual, a business, or an organization, there is a place for you
            in our mission to drive sustainable change.
          </p>
          <Link
            to="/contact"
            className="group relative inline-flex items-center justify-center px-10 py-4 font-bold text-white bg-gradient-to-r from-[#359381] to-[#359381] rounded-lg shadow-lg overflow-hidden transform transition-all duration-300 hover:scale-105 hover:bg-[#003329]"
          >
            <span className="relative z-10">Contact Us</span>
            <ChevronRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
            <div className="absolute inset-0 bg-gradient-to-r from-[#003329] to-[#003329] opacity-0 group-hover:opacity-100 transition-opacity" />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
