// src/pages/CarbonEmissions.js
import React from "react";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";
import redFootImage from "../assets/redfoot.png";
import Content from "../components/IphoneLayout";
import { ActionButton } from "../components/HomePage";
import TopHeader from "../components/TopHeader"; // Use shared header

const CarbonEmissions = () => {
  const navigate = useNavigate();

  return (
    <Content>
      <TopHeader /> {/* Shared header */}
      <EmissionImage src={redFootImage} alt="Footprint" />
      <EmissionText>Your emissions: 4727kg of CO₂e</EmissionText>
      <DescriptionText>
        Arthur, if everyone in Dubai emitted CO₂ like you, it could lead to
        1,750 more heat-related illnesses annually. Your emissions matter—small
        changes can protect others' health.
      </DescriptionText>
      <Link to="/prototype/recommendations">
        <ActionButton onClick={() => navigate("/recommendations")}>
          Recommendations
        </ActionButton>
      </Link>
      <Link to="/prototype/greenInvestments">
        <ActionButton onClick={() => navigate("/greenInvestments")}>
          Green Investments
        </ActionButton>
      </Link>
    </Content>
  );
};

export default CarbonEmissions;

// Styled-components for styling
const EmissionImage = styled.img`
  margin-top: 10px;
  width: 50px;
  height: auto;
`;

const EmissionText = styled.h1`
  font-family: var(--sds-typography-heading-font-family);
  font-size: var(--sds-typography-heading-size-base);
  font-weight: 800;
  letter-spacing: -0.02em;
  text-align: center;
  color: #e54e45;
  margin-top: 24px;
`;

const DescriptionText = styled.p`
  font-family: var(--sds-typography-body-font-family);
  font-size: 15px;
  font-weight: 800;
  text-align: center;
  color: #fff;
  margin-top: 16px;
  max-width: 90%;
  margin-bottom: 16px;
`;
