// src/components/TopHeader.js
import React from "react";
import styled from "styled-components";
import { useNavigate, Link, useLocation } from "react-router-dom";
import logo from "../assets/logo/Carbon2Capital.svg";
import BackButton from "./BackButton"; // Assuming you already have this component

const TopHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackNavigation = () => {
    if (location.pathname === "/prototype/carbonEmissions") {
      navigate("/prototype");
    } else if (
      location.pathname === "/prototype/recommendations" ||
      location.pathname === "/prototype/greenInvestments"
    ) {
      navigate("/prototype/carbonEmissions");
    }
  };

  return (
    <Header>
      <BackButton onClick={handleBackNavigation} />
      <Link to="/prototype">
        <Logo src={logo} alt="Carbon2Capital Logo" />
      </Link>
    </Header>
  );
};

export default TopHeader;

// Styled-components for styling
const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #013130;
  padding: 16px;
  border-radius: 38px 38px 0px 0px;
  position: sticky;
  top: 0;
  width: 100%;
`;

const Logo = styled.img`
  width: 80px;
  height: auto;
`;
