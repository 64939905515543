import React from "react";
import Tilt from "react-parallax-tilt"; // Install via `npm install react-parallax-tilt`
import { motion } from "framer-motion"; // Install via `npm install framer-motion`
// eslint-disable-next-line import/no-unresolved
import { Coffee, Heart, Leaf } from "lucide-react";

// Motion Variants for Animation
const fadeIn = (
  direction = "up",
  type = "spring",
  delay = 0,
  duration = 0.75
) => {
  return {
    hidden: {
      y: direction === "up" ? 50 : direction === "down" ? -50 : 0,
      x: direction === "left" ? -50 : direction === "right" ? 50 : 0,
      opacity: 0,
    },
    visible: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        type,
        delay,
        duration,
        ease: "easeOut",
      },
    },
  };
};

// Card Component with Tilt and Motion
const InteractiveCard = ({ index, icon: Icon, title, description }) => (
  <Tilt tiltMaxAngleX={20} tiltMaxAngleY={20} scale={1.1} transitionSpeed={450}>
    <motion.div
      variants={fadeIn("up", "spring", index * 0.2, 0.75)}
      initial="hidden"
      animate="visible"
      className="bg-gray-50 p-6 rounded-xl shadow-md cursor-pointer flex flex-col items-center justify-between min-h-[280px]"
    >
      <div className="mb-4 flex justify-center items-center">
        <Icon className="h-12 w-12 text-[#359381]" />
      </div>
      <h3 className="text-xl font-bold mb-3 text-center">{title}</h3>
      <p className="text-gray-600 text-center">{description}</p>
    </motion.div>
  </Tilt>
);

// Main Section
const UnderstandingSection = () => {
  const cardsData = [
    {
      icon: Coffee,
      title: "Your Daily Choices",
      description:
        "From your morning coffee to evening commute, see how your routine connects to the bigger picture. Our AI turns your bank statements into personal climate stories.",
    },
    {
      icon: Heart,
      title: "Learn Through Play",
      description:
        "Challenge your assumptions with our interactive trivia. Compare everyday items and discover surprising climate connections.",
    },
    {
      icon: Leaf,
      title: "Personal Growth",
      description:
        "Get personalized suggestions that fit your lifestyle. Small changes add up to meaningful impact.",
    },
  ];

  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">
          Understanding Climate Impact, One Story at a Time
        </h2>
        <motion.div
          className="grid md:grid-cols-3 gap-8"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1, transition: { staggerChildren: 0.2 } },
          }}
        >
          {cardsData.map((card, index) => (
            <InteractiveCard key={index} index={index} {...card} />
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default UnderstandingSection;
