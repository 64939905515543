import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import {
  ChevronRight,
  Building2,
  Lightbulb,
  Building,
  TreePine,
  Rocket,
  LineChart,
  Target,
  Users,
  BarChart,
} from "lucide-react";

const Solutions = () => {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const solutions = {
    banks: [
      {
        title: "Carbon Tracking",
        description:
          "Help customers measure their emissions and make greener choices.",
        icon: <TreePine className="w-8 h-8" />,
        color: "from-emerald-400 to-emerald-500",
      },
      {
        title: "Green Investment Opportunities",
        description:
          "Curated green portfolios to help customers offset carbon and grow wealth.",
        icon: <LineChart className="w-8 h-8" />,
        color: "from-teal-400 to-teal-500",
      },
    ],
    businesses: [
      {
        title: "ESG Reporting",
        description:
          "Streamline sustainability reporting with automated insights and compliance tools.",
        icon: <BarChart className="w-8 h-8" />,
        color: "from-blue-400 to-blue-500",
      },
      {
        title: "Emission Reduction Strategies",
        description:
          "Tailored strategies to optimize operations and minimize emissions.",
        icon: <Target className="w-8 h-8" />,
        color: "from-indigo-400 to-indigo-500",
      },
    ],
    startups: [
      {
        title: "Sustainability Tools",
        description:
          "Access tools to measure and improve carbon impact from day one.",
        icon: <Lightbulb className="w-8 h-8" />,
        color: "from-purple-400 to-purple-500",
      },
      {
        title: "Visibility and Education",
        description:
          "Highlight your sustainability efforts and educate your team.",
        icon: <Users className="w-8 h-8" />,
        color: "from-pink-400 to-pink-500",
      },
    ],
  };

  const SolutionCard = ({ title, description, icon, color, index }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
      <motion.div
        className="relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        layout
      >
        <div
          className={`relative p-8 rounded-lg bg-white transform transition-all duration-300 ease-out ${
            isHovered ? "shadow-lg scale-105" : "shadow-md"
          }`}
        >
          <div className="flex items-start space-x-6">
            <div
              className={`p-4 rounded-xl bg-gradient-to-br ${color} text-white transform transition-all duration-300 ${
                isHovered ? "scale-110" : "scale-100"
              }`}
            >
              {icon}
            </div>
            <div className="flex-1 space-y-2">
              <h4 className="text-xl font-bold text-gray-800">{title}</h4>
              <p className="text-gray-600">{description}</p>
            </div>
          </div>

          <AnimatePresence>
            {isHovered && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                transition={{ duration: 0.2 }}
                className="absolute bottom-4 right-4"
              >
                <ChevronRight className="w-5 h-5 text-gray-400" />
              </motion.div>
            )}
          </AnimatePresence>

          <motion.div
            className="absolute bottom-0 left-0 h-1 bg-gradient-to-r from-[#359381] to-[#1d473e]"
            initial={{ width: "0%" }}
            animate={{ width: isHovered ? "100%" : "0%" }}
            transition={{ duration: 0.3 }}
          />
        </div>
      </motion.div>
    );
  };

  const SectionHeader = ({ icon, title, description }) => (
    <div className="text-center mb-12">
      <div className="flex justify-center mb-4">
        <div className="p-3 rounded-full bg-gradient-to-r from-[#359381] to-[#1d473e] text-white">
          {icon}
        </div>
      </div>
      <h3 className="text-3xl font-bold mb-4 text-gray-800">{title}</h3>
      <p className="text-lg text-gray-600 max-w-2xl mx-auto">{description}</p>
    </div>
  );

  return (
    <section className="py-20 bg-gradient-to-b from-gray-100 to-white min-h-screen">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold bg-gradient-to-r from-[#359381] to-[#359381] bg-clip-text text-transparent mb-6">
            Transforming Sustainability
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Tailored solutions for banks, businesses, and startups. Let's shape
            a greener future together.
          </p>
        </div>

        <div className="space-y-24">
          <div id="#banks">
            <SectionHeader
              icon={<Building className="w-8 h-8" />}
              title="For Banks"
              description="Empower your customers with carbon tracking and green investment opportunities."
            />
            <div className="grid md:grid-cols-2 gap-8">
              {solutions.banks.map((solution, index) => (
                <SolutionCard
                  key={index}
                  {...solution}
                  index={`bank-${index}`}
                />
              ))}
            </div>
          </div>

          <div id="businesses">
            <SectionHeader
              icon={<Building2 className="w-8 h-8" />}
              title="For Businesses"
              description="Simplify ESG reporting and align operations with sustainability goals."
            />
            <div className="grid md:grid-cols-2 gap-8">
              {solutions.businesses.map((solution, index) => (
                <SolutionCard
                  key={index}
                  {...solution}
                  index={`business-${index}`}
                />
              ))}
            </div>
          </div>

          <div id="startups">
            <SectionHeader
              icon={<Rocket className="w-8 h-8" />}
              title="For Startups"
              description="Build a sustainable foundation and attract eco-conscious investors."
            />
            <div className="grid md:grid-cols-2 gap-8">
              {solutions.startups.map((solution, index) => (
                <SolutionCard
                  key={index}
                  {...solution}
                  index={`startup-${index}`}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="text-center mt-16">
          <Link
            to="/contact"
            className="group relative inline-flex items-center justify-center px-10 py-4 font-bold text-white bg-gradient-to-r from-[#359381] to-[#359381] rounded-lg shadow-lg overflow-hidden transform transition-all duration-300 hover:scale-105 hover:bg-[#003329]"
          >
            <span className="relative z-10">Contact Us</span>
            <ChevronRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
            <div className="absolute inset-0 bg-gradient-to-r from-[#003329] to-[#003329] opacity-0 group-hover:opacity-100 transition-opacity" />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Solutions;
