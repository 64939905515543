import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import confetti from "canvas-confetti";

const fireConfetti = () => {
  const duration = 5 * 1000;
  const end = Date.now() + duration;

  const frame = () => {
    confetti({
      particleCount: 2,
      angle: 60,
      spread: 55,
      origin: { x: 0 },
    });
    confetti({
      particleCount: 2,
      angle: 120,
      spread: 55,
      origin: { x: 1 },
    });

    if (Date.now() < end) {
      requestAnimationFrame(frame);
    }
  };
  frame();
};

const carbonActivities = [
  {
    id: 1,
    name: "🚗 Driving 150 km in a Car",
    carbon: 60.6,
    details:
      "Highest impact due to fossil fuel combustion and vehicle emissions",
  },
  {
    id: 2,
    name: "✈️ Taking a 150 km Short Flight",
    carbon: 20,
    details: "High emissions from jet fuel and altitude impact",
  },
  {
    id: 3,
    name: "🍔 Eating a Big Beef Shawarma",
    carbon: 6.5,
    details: "Significant impact from cattle farming and meat processing",
  },
  {
    id: 4,
    name: "🍗 Eating a Plate of Grilled Chicken",
    carbon: 5,
    details:
      "Lower impact than beef but still contributes to agricultural emissions",
  },
  {
    id: 5,
    name: "👖 Buying a New Pair of Jeans",
    carbon: 10,
    details:
      "Cotton farming, manufacturing, and transport contribute to emissions",
  },
  {
    id: 6,
    name: "🚿 Taking a 10-Minute Hot Shower",
    carbon: 2.5,
    details: "Energy used for water heating creates moderate emissions",
  },
  {
    id: 7,
    name: "💡 Leaving a Light Bulb On for 24 Hours",
    carbon: 1.2,
    details: "Continuous electricity consumption from power plants",
  },
  {
    id: 8,
    name: "🎮 Gaming for 6 Hours on a Console",
    carbon: 1.44,
    details: "Power consumption from console and display usage",
  },
  {
    id: 9,
    name: "📺 Streaming YouTube for 10 Hours",
    carbon: 1,
    details: "Data center energy use and network transmission",
  },
  {
    id: 10,
    name: "🍔 Producing 67 Plastic Bottles",
    carbon: 22,
    details: "Oil extraction, manufacturing, and transport emissions",
  },
  {
    id: 11,
    name: "🚴 Riding an E-Bike for 25 km",
    carbon: 4.5,
    details: "Battery charging and manufacturing emissions",
  },
  {
    id: 12,
    name: "📱 Charging a Smartphone 300 Times",
    carbon: 1.5,
    details: "Cumulative impact of frequent small energy usage",
  },
  {
    id: 13,
    name: "☕ Brewing 15 Cups of Coffee",
    carbon: 2.1,
    details: "Energy for brewing and coffee production emissions",
  },
];

const getRandomActivities = (count = 4) => {
  return [...carbonActivities].sort(() => 0.5 - Math.random()).slice(0, count);
};

const InteractiveGame = () => {
  const [activities, setActivities] = useState(getRandomActivities());
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [score, setScore] = useState(null);
  const [parallaxOffset, setParallaxOffset] = useState(0);

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("draggedIndex", index);
  };

  const handleDrop = (event, index) => {
    const draggedIndex = event.dataTransfer.getData("draggedIndex");
    if (draggedIndex === undefined) return;

    const updatedActivities = [...activities];
    const [draggedActivitiy] = updatedActivities.splice(draggedIndex, 1);
    updatedActivities.splice(index, 0, draggedActivitiy);

    setActivities(updatedActivities);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleSubmit = () => {
    const correctOrder = [...activities].sort((a, b) => b.carbon - a.carbon);
    const userCorrectItems = activities.filter(
      (activitiy, index) => activitiy.id === correctOrder[index].id
    ).length;

    const calculatedScore = Math.round(
      (userCorrectItems / activities.length) * 100
    );
    setScore(calculatedScore);
    setIsSubmitted(true);
    fireConfetti();
  };

  const resetGame = () => {
    setActivities(getRandomActivities());
    setIsSubmitted(false);
    setScore(null);
  };

  useEffect(() => {
    const handleScroll = () => {
      setParallaxOffset(window.scrollY * 0.2);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <section className="pt-16 bg-gray-50 relative overflow-hidden">
      {/* Artistic Background Elements */}
      <div
        className="absolute top-0 left-10 h-32 w-32 bg-yellow-100 rounded-full opacity-50"
        style={{
          transform: `translateY(${parallaxOffset}px)`,
        }}
      />
      <div
        className="absolute top-10 right-20 h-40 w-40 bg-green-100 rounded-full opacity-60"
        style={{
          transform: `translateY(${parallaxOffset * -1.5}px)`,
        }}
      />

      <div className="container mx-auto px-4 relative">
        <div className="max-w-3xl mx-auto text-center mb-12">
          <h2 className="text-3xl font-bold mb-0 text-[#003329]">
            Think You Know Carbon Emissions?
          </h2>
          <h3 className="text-2xl font-bold mb-6 text-[#003329]">
            Prepare to Be Shocked!
          </h3>
          <p className="text-xl text-gray-600">
            Drag and drop to rank these from <b>highest to lowest emissions</b>:
          </p>
        </div>

        <div className="max-w-2xl mx-auto bg-white p-8 rounded-xl shadow-sm">
          {!isSubmitted ? (
            <>
              <div className="space-y-4">
                {activities.map((activitiy, index) => (
                  <div
                    key={activitiy.id}
                    draggable
                    onDragStart={(e) => handleDragStart(e, index)}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, index)}
                    className="bg-gray-50 p-4 rounded-lg border flex items-center justify-between cursor-grab hover:shadow-md transition-transform transform hover:scale-105"
                  >
                    <span className="font-medium">{activitiy.name}</span>
                    <span className="text-gray-500">Drag to rank</span>
                  </div>
                ))}
              </div>
              <div className="text-center mt-8">
                <button
                  onClick={handleSubmit}
                  className="bg-[#359381] text-white font-medium px-6 py-2 rounded hover:bg-[#003329] overflow-hidden transform transition-all duration-300 hover:scale-105"
                >
                  Discover Your Impact →
                </button>
              </div>
            </>
          ) : (
            <div className="text-center">
              <h3 className="text-2xl font-bold mb-4">Your Score: {score}%</h3>
              <p className="text-xl text-gray-600 mb-6">
                {score === 100
                  ? "Perfect! You nailed the rankings!"
                  : score > 0
                  ? "Great effort! You're on the right path to understanding carbon footprints."
                  : "Keep learning! Small steps make a big difference."}
              </p>
              <div className="space-y-4">
                {[...activities]
                  .sort((a, b) => b.carbon - a.carbon)
                  .map((activitiy) => (
                    <div
                      key={activitiy.id}
                      className="bg-green-50 p-4 rounded-lg border flex items-center justify-between"
                    >
                      <span className="font-medium">{activitiy.name}</span>
                      <div>
                        <span className="text-green-700">
                          {activitiy.carbon} kg CO₂
                        </span>
                        <p className="text-gray-500 text-sm">
                          {activitiy.details}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="text-center mt-8">
                <button
                  onClick={resetGame}
                  className="bg-[#359381] text-white font-medium px-6 py-2 rounded hover:bg-[#003329] overflow-hidden transform transition-all duration-300 hover:scale-105"
                >
                  Try Again
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        className="absolute bottom-0 right-10 h-24 w-24 bg-blue-100 rounded-full opacity-50"
        style={{
          transform: `translateY(${parallaxOffset * -1}px)`,
        }}
      />

      <div className="container mx-auto px-4 relative pt-10">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6 text-[#003329]">
            Experience Our Prototype
          </h2>
          <p className="text-xl text-gray-600 mb-8">
            Dive deeper into how Carbon2Capital transforms carbon literacy into
            actionable insights. Explore our interactive prototype and see the
            future of sustainability.
          </p>
          <div className="text-center">
            <Link
              to="/prototype"
              className="bg-[#359381] text-white px-8 py-3 rounded-lg font-medium hover:bg-[#003329] inline-block overflow-hidden transform transition-all duration-300 hover:scale-105"
            >
              Explore the Prototype →
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InteractiveGame;
