// src/pages/GreenInvestments.js
import React from "react";
import styled from "styled-components";
import Content from "../components/IphoneLayout";
import TopHeader from "../components/TopHeader";
import greenProjects from "../data/greenProjects";

const GreenInvestments = () => {
  return (
    <Content>
      <TopHeader />
      <ScrollableContainer>
        <Title>Green Investments</Title>
        <Subtitle>
          Sustainable investment opportunities tailored for you!
        </Subtitle>

        <ProjectList>
          {greenProjects.map((project, index) => (
            <ProjectCard key={index}>
              <ProjectTitle>{project.title}</ProjectTitle>
              <ProjectDescription>{project.description}</ProjectDescription>
              <ProjectDetails>
                <Detail>Related SDG: {project.sdg}</Detail>
                <Detail>Return: {project.return}</Detail>
                <Detail>Payback Period: {project.paybackPeriod}</Detail>
                <Detail>
                  AED to Invest to Offset Emission: {project.offsetCost}
                </Detail>
              </ProjectDetails>
              <LearnMoreButton>Learn more</LearnMoreButton>
            </ProjectCard>
          ))}
        </ProjectList>
      </ScrollableContainer>
    </Content>
  );
};

export default GreenInvestments;

// Styled-components for styling
const ScrollableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 13px;
  height: calc(100vh - 100px); /* Adjust for header height */
  overflow-y: auto;
  width: 95%;
  box-sizing: border-box;
`;

const Title = styled.h3`
  text-align: center;
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 5px;
`;

const Subtitle = styled.p`
  text-align: center;
  color: #ffffff;
  margin-top: 0px;
  margin-bottom: 10x;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
`;

const ProjectList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const ProjectCard = styled.div`
  background-color: #ffffff;
  border-radius: 12px;
  padding: 3px 7px;
  border-top: 1px solid #d9d9d9;
`;

const ProjectTitle = styled.h3`
  font-weight: 600;
  color: #000;
  margin-bottom: 2px;
  margin-top: 8px;
`;

const ProjectDescription = styled.p`
  color: #333;
  font-size: 12px;
  margin-bottom: 8px;
  white-space: pre-line;
  text-align: left;
`;

const ProjectDetails = styled.div`
  margin-bottom: 10px;
  font-size: 13px;
  color: #000;
  text-align: left;
`;

const Detail = styled.p`
  margin: 4px 0;
`;

const LearnMoreButton = styled.button`
  background: none;
  border: 1px solid #767676;
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 13px;
  color: #333;
  cursor: pointer;
  font-weight: 600;
  width: fit-content;
  &:hover {
    background-color: #f0f0f0;
  }
`;
