// src/components/HomePage.js
import React from "react";
import styled from "styled-components";
import Carbon2CapitalLogo from "../assets/logo/Carbon2Capital.svg";
import FABLogo from "../assets/logo/FAB.svg";
import { useNavigate, Link } from "react-router-dom";

// Styled-components for styling
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Logo = styled.img`
  width: 60%;
  margin-bottom: 20px;
`;

const Tagline = styled.p`
  font-family: var(--sds-typography-body-font-family);
  font-size: var(--sds-typography-body-size-medium);
  font-weight: 600;
  text-align: center;
  color: white;
  margin-bottom: 30px;
`;

const ActionButton = styled.button`
  width: 189px;
  height: 41px;
  background-color: #0b584a;
  color: #ffffff;
  font-weight: 600;
  font-size: var(--sds-typography-body-size-medium);
  border: none;
  border-radius: 8px;
  margin-bottom: 15px;
  cursor: pointer;
`;

const FABLogoImage = styled.img`
  width: 60%;
  margin-top: 30px;
`;

const HomePage = () => {
  const navigate = useNavigate();

  const goToBankStatement = () => navigate("/bankStatement");
  const goToCarbonEmissions = () => navigate("/carbonEmissions");

  return (
    <PageContainer>
      <Logo src={Carbon2CapitalLogo} alt="Carbon2Capital Logo" />
      <Tagline>Go Green, Invest Clean</Tagline>
      <Link to="/prototype/bankStatement">
        <ActionButton onClick={goToBankStatement}>Bank Statement</ActionButton>
      </Link>
      <Link to="/prototype/carbonEmissions">
        <ActionButton onClick={goToCarbonEmissions}>
          Carbon Emissions
        </ActionButton>
      </Link>
      <FABLogoImage src={FABLogo} alt="FAB Logo" />
    </PageContainer>
  );
};

export default HomePage;

export { ActionButton };
