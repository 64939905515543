import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Lightbulb,
  Globe,
  BarChart,
  TreePine,
  ChevronRight,
} from "lucide-react";
import EarthCanvas from "../components/EarthCanvas";
import CarbonQuiz from "../components/CarbonQuiz";

const CarbonLiteracy = () => {
  const topics = [
    {
      title: "What is Carbon Literacy?",
      content:
        "Carbon Literacy is the awareness of how everyday activities contribute to carbon emissions. It empowers individuals and organizations to reduce their carbon footprint by making informed decisions.",
      icon: <Lightbulb className="w-10 h-10 text-teal-500" />,
      color: "from-teal-400 to-teal-500",
    },
    {
      title: "The Carbon Cycle",
      content:
        "Carbon is a natural part of our world, cycling between the atmosphere, oceans, and living organisms. However, human activities have disrupted this balance, causing an increase in greenhouse gases.",
      icon: <Globe className="w-10 h-10 text-blue-500" />,
      color: "from-blue-400 to-blue-500",
    },
    {
      title: "The Biggest Culprits",
      content:
        "Energy, transportation, and agriculture are the top contributors to global emissions. Learn how your choices in these areas affect your carbon footprint.",
      icon: <BarChart className="w-10 h-10 text-indigo-500" />,
      color: "from-indigo-400 to-indigo-500",
    },
    {
      title: "Actionable Steps",
      content:
        "Small changes in daily habits—like using energy-efficient appliances, reducing food waste, and opting for public transport—can have a big impact on reducing your emissions.",
      icon: <TreePine className="w-10 h-10 text-green-500" />,
      color: "from-green-400 to-green-500",
    },
  ];

  const KnowledgeCard = ({ title, content, icon, color }) => (
    <motion.div className="relative" whileHover={{ scale: 1.05 }} layout>
      <div
        className={`relative p-6 min-h-[12rem] rounded-lg bg-white shadow-md transform transition-all duration-300 ease-out`}
      >
        <div className="flex items-start space-x-6">
          <div
            className={`p-4 rounded-xl bg-gradient-to-br ${color} text-white transform transition-all duration-300`}
          >
            {icon}
          </div>
          <div className="flex-1 space-y-2">
            <h4 className="text-xl font-bold text-gray-800">{title}</h4>
            <p className="text-gray-600">{content}</p>
          </div>
        </div>
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.2 }}
            className="absolute bottom-4 right-4"
          >
            <ChevronRight className="w-5 h-5 text-gray-400" />
          </motion.div>
        </AnimatePresence>
      </div>
    </motion.div>
  );

  return (
    <section className="py-20 bg-gradient-to-b from-gray-100 to-white">
      <div className="container mx-auto px-4">
        {/* Page Header */}
        <div className="text-center mb-2">
          <h1 className="text-4xl font-bold bg-gradient-to-r from-[#359381] to-[#359381] bg-clip-text text-transparent mb-6">
            The Bigger Picture: Why Carbon Literacy Matters
          </h1>
          <p className="text-xl text-gray-600 max-w-4xl mx-auto">
            Every choice we make—what we eat, how we travel, where we
            invest—adds up. At Carbon2Capital, we believe knowledge is the first
            step to meaningful action. Together, we can shape a world where
            sustainability is second nature.
          </p>
        </div>

        {/* Interactive 3D Earth Model */}
        <div className="mb-2 flex justify-center">
          <div className="w-full h-80 md:w-1/2 md:h-[400px]">
            <EarthCanvas />
          </div>
        </div>

        {/* Informative Topics */}
        <div className="grid md:grid-cols-2 gap-8 mb-16">
          {topics.map((topic, index) => (
            <KnowledgeCard key={index} {...topic} />
          ))}
        </div>

        {/* Interactive Quiz */}
        <CarbonQuiz />

        {/* Closing CTA */}
        <div className="text-center mt-16">
          <h1 className="text-3xl font-bold text-gray-800 leading-tight">
            Become Officially Carbon Literate
          </h1>
          <p className="mt-4 text-lg text-gray-600 mb-4">
            Join this comprehensive course designed to make you officially
            Carbon Literate, equipping you with the knowledge and skills to
            tackle climate change effectively. Learn from industry expert David
            May and become a catalyst for sustainable change.
          </p>
          <button className="group relative inline-flex items-center justify-center px-10 py-4 font-bold text-white bg-gradient-to-r from-[#359381] to-[#359381] rounded-lg shadow-lg overflow-hidden transform transition-all duration-300 hover:scale-105 hover:bg-[#003329]">
            <span className="relative z-10">Sign Up</span>
            <ChevronRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
            <div className="absolute inset-0 bg-gradient-to-r from-[#003329] to-[#003329] opacity-0 group-hover:opacity-100 transition-opacity" />
          </button>
        </div>
      </div>
    </section>
  );
};

export default CarbonLiteracy;
