// src/pages/Recommendations.js
import React, { useState } from "react";
import styled from "styled-components";
import Content from "../components/IphoneLayout";
import TopHeader from "../components/TopHeader";

const recommendations = [
  {
    title: "Electronics",
    content:
      "By choosing eco-friendly electronics or repairing devices instead of purchasing new ones, you can significantly cut down emissions from your tech purchases.",
  },
  {
    title: "Groceries",
    content:
      "Opting for more sustainable grocery brands or reducing waste during grocery runs could make a big impact, considering it's one of your top spending areas.",
  },
  {
    title: "Malls and Entertainment",
    content:
      "When visiting malls or entertainment venues, consider public transportation to reduce the emissions from frequent trips.",
  },
];

const Recommendations = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleActiveIndex = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <Content>
      <TopHeader />
      <Title>Recommendations</Title>
      <Subtitle>Personalized based on your spendings</Subtitle>
      {recommendations.map((item, index) => (
        <RecommendationCard key={index}>
          <CardHeader onClick={() => toggleActiveIndex(index)}>
            <CardTitle>{item.title}</CardTitle>
            <ExpandIcon>{activeIndex === index ? "▲" : "▼"}</ExpandIcon>
          </CardHeader>
          {activeIndex === index && <CardContent>{item.content}</CardContent>}
        </RecommendationCard>
      ))}
    </Content>
  );
};

export default Recommendations;

// Styled-components for styling
const Title = styled.h2`
  text-align: center;
  color: #ffffff;
  font-weight: 600;
  margin-top: 20px;
`;

const Subtitle = styled.p`
  text-align: center;
  color: #ffffff;
  margin: 10px 0px;
  font-weight: 600;
`;

const RecommendationCard = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  margin: 6px;
  padding: 0px 3px;
  width: 80%;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 0px;
`;

const CardTitle = styled.h3`
  font-weight: 600;
  color: #000;
  font-size: 14px;
`;

const ExpandIcon = styled.span`
  font-size: 12px;
`;

const CardContent = styled.p`
  margin-top: 0px;
  color: #333;
  font-size: 13px;
  text-align: left;
  padding-bottom: 10px;
`;
