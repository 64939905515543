// src/pages/Prototype.js
import React from "react";
import "./Prototype.css";
import IphoneLayout from "../components/IphoneLayout";
import HomePage from "../components/HomePage";
import BankStatement from "../components/BankStatement";
import CarbonEmissions from "./CarbonEmissions";
import Recommendations from "./Recommendations";
import GreenInvestments from "./GreenInvestments";
import { Routes, Route } from "react-router-dom";

function Prototype() {
  return (
    <IphoneLayout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/bankStatement" element={<BankStatement />} />
        <Route path="/carbonEmissions" element={<CarbonEmissions />} />
        <Route path="/recommendations" element={<Recommendations />} />
        <Route path="/greenInvestments" element={<GreenInvestments />} />
      </Routes>
    </IphoneLayout>
  );
}

export default Prototype;
