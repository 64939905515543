// App.js
import React, { useRef, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  NavLink,
} from "react-router-dom";
import "./style.css";
import logo from "./assets/logo-new.png";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Solutions from "./pages/Solutions";
import Contact from "./pages/Contact";
import Prototype from "./pages/Prototype";
import Footer from "./components/Footer";
import CarbonLiteracy from "./pages/CarbonLiteracy";
import ScrollToTop from "./components/ScrollToTop";

const LoginRedirect = () => {
  useEffect(() => {
    window.location.href = "https://app.carbon2capital.com/login";
  }, []);
  return null; // No need to render anything
};

function App() {
  const [openNavigation, setOpenNavigation] = useState(false);
  const textRef = useRef(null);
  const leafRef = useRef(null);
  const hill1Ref = useRef(null);
  const hill4Ref = useRef(null);
  const hill5Ref = useRef(null);

  const toggleNavigation = () => {
    if (openNavigation) {
      setOpenNavigation(false);
      document.body.style.overflow = "auto";
    } else {
      setOpenNavigation(true);
      document.body.style.overflow = "hidden";
    }
  };

  const handleClick = () => {
    if (!openNavigation) return;
    document.body.style.overflow = "auto";
    setOpenNavigation(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const value = window.scrollY;
      const isMobile = window.innerWidth <= 840;
      const scaleFactor = isMobile ? 1.2 : 2.5;

      if (textRef.current)
        textRef.current.style.marginTop = value * scaleFactor + "px";
      if (leafRef.current) {
        leafRef.current.style.top = value * (isMobile ? -1 : -1.5) + "px";
        leafRef.current.style.left = value * (isMobile ? 1 : 1.5) + "px";
      }
      if (hill5Ref.current)
        hill5Ref.current.style.left = value * (isMobile ? 1 : 1.5) + "px";
      if (hill4Ref.current)
        hill4Ref.current.style.left = value * (isMobile ? -1 : -1.5) + "px";
      if (hill1Ref.current)
        hill1Ref.current.style.top = value * (isMobile ? 0.3 : 0.5) + "px";
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Router>
      <div
        className={`fixed top-0 left-0 w-full z-50 border-b ${
          openNavigation
            ? "bg-[#003329] opacity-95"
            : "bg-[#003329]/90 backdrop-blur-sm"
        }`}
      >
        <div className="flex items-center px-5 lg:px-7.5 xl:px-10 max-lg:py-2">
          <NavLink to="/" className="block w-[12rem] xl:mr-8">
            <img src={logo} alt="Company Logo" className="h-16 w-auto" />
          </NavLink>

          <nav
            className={`${
              openNavigation ? "flex" : "hidden"
            } fixed top-[5rem] left-0 right-0 bottom-0 bg-[#003329] lg:static lg:flex lg:mx-auto lg:bg-transparent`}
          >
            <div className="relative z-2 flex flex-col items-center justify-center m-auto lg:flex-row">
              <NavLink
                to="/"
                end
                onClick={handleClick}
                className={({ isActive }) =>
                  `block relative text-2xl uppercase text-white transition-colors hover:text-[#359381] px-6 py-6 md:py-8 lg:text-sm lg:font-semibold lg:leading-5 xl:px-10 ${
                    isActive ? "text-[#359381]" : ""
                  }`
                }
              >
                Home
              </NavLink>
              <NavLink
                to="/about-us"
                onClick={handleClick}
                className={({ isActive }) =>
                  `block relative text-2xl uppercase text-white transition-colors hover:text-[#359381] px-6 py-6 md:py-8 lg:text-sm lg:font-semibold lg:leading-5 xl:px-10 ${
                    isActive ? "text-[#359381]" : ""
                  }`
                }
              >
                About Us
              </NavLink>
              <NavLink
                to="/solutions"
                onClick={handleClick}
                className={({ isActive }) =>
                  `block relative text-2xl uppercase text-white transition-colors hover:text-[#359381] px-6 py-6 md:py-8 lg:text-sm lg:font-semibold lg:leading-5 xl:px-10 ${
                    isActive ? "text-[#359381]" : ""
                  }`
                }
              >
                Solutions
              </NavLink>
              <NavLink
                to="/carbonliteracy"
                onClick={handleClick}
                className={({ isActive }) =>
                  `block relative text-2xl uppercase text-white transition-colors hover:text-[#359381] px-6 py-6 md:py-8 lg:text-sm lg:font-semibold lg:leading-5 xl:px-10 ${
                    isActive ? "text-[#359381]" : ""
                  }`
                }
              >
                Education
              </NavLink>
              <NavLink
                to="/prototype"
                onClick={handleClick}
                className={({ isActive }) =>
                  `block relative text-2xl uppercase text-white transition-colors hover:text-[#359381] px-6 py-6 md:py-8 lg:text-sm lg:font-semibold lg:leading-5 xl:px-10 ${
                    isActive ? "text-[#359381]" : ""
                  }`
                }
              >
                Prototype
              </NavLink>
              <NavLink
                to="/contact"
                onClick={handleClick}
                className={({ isActive }) =>
                  `block relative text-2xl uppercase text-white transition-colors hover:text-[#359381] px-6 py-6 md:py-8 lg:text-sm lg:font-semibold lg:leading-5 xl:px-10 ${
                    isActive ? "text-[#359381]" : ""
                  }`
                }
              >
                Contact
              </NavLink>
              <NavLink
                to="/login"
                onClick={handleClick}
                className={({ isActive }) =>
                  `block relative text-2xl uppercase text-white transition-colors hover:text-[#359381] px-6 py-6 md:py-8 lg:text-sm lg:font-semibold lg:leading-5 xl:px-10 ${
                    isActive ? "text-[#359381]" : ""
                  }`
                }
              >
                Login
              </NavLink>
            </div>
          </nav>

          {/* Mobile Menu Button */}
          <button
            className="ml-auto lg:hidden text-white p-2"
            onClick={toggleNavigation}
          >
            {openNavigation ? (
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </button>
        </div>
      </div>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/prototype/*" element={<Prototype />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/carbonliteracy" element={<CarbonLiteracy />} />
        <Route path="/login" element={<LoginRedirect />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
