import React, { useRef, useEffect } from "react";
import { ArrowRight } from "lucide-react";
import InteractiveGame from "../components/InteractiveGame";
import CarbonFootprint from "../components/CarbonFootprint";
import EarthCanvas from "../components/EarthCanvas";
import UnderstandingSection from "../components/UnderstandingSection";
import { Link } from "react-router-dom";

const HomePage = () => {
  const textRef = useRef(null);
  const leafRef = useRef(null);
  const hill1Ref = useRef(null);
  const hill3Ref = useRef(null);
  const treeRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const value = window.scrollY;
      const isMobile = window.innerWidth <= 768;
      const scaleFactor = isMobile ? 0.8 : 2.5;

      if (textRef.current)
        textRef.current.style.marginTop = value * scaleFactor + "px";
      if (leafRef.current) {
        leafRef.current.style.top = value * (isMobile ? -1 : -1.5) + "px";
        leafRef.current.style.left = value * (isMobile ? 1 : 1.5) + "px";
      }
      if (hill3Ref.current)
        hill3Ref.current.style.left = value * (isMobile ? 1 : 1.5) + "px";
      if (treeRef.current)
        treeRef.current.style.left = value * (isMobile ? -1 : -1.5) + "px";
      if (hill1Ref.current)
        hill1Ref.current.style.top = value * (isMobile ? 0.3 : 0.5) + "px";
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="min-h-screen">
      {/* Parallax Hero Section */}
      <section className="parallax relative h-screen overflow-hidden">
        <img
          src="/images/background/hill1.png"
          ref={hill1Ref}
          id="hill1"
          alt="hill1"
          className="absolute"
        />
        <img
          src="/images/background/hill2.png"
          id="hill2"
          alt="hill2"
          className="absolute"
        />
        <img
          src="/images/background/hill3.png"
          ref={hill3Ref}
          id="hill3"
          alt="hill3"
          className="absolute"
        />
        <img
          src="/images/background/hill4.png"
          id="hill4"
          alt="hill4"
          className="absolute"
        />
        <img
          src="/images/background/hill5.png"
          id="hill5"
          alt="hill5"
          className="absolute"
        />
        <img
          src="/images/background/tree.png"
          ref={treeRef}
          id="tree"
          alt="tree"
          className="absolute"
        />
        <h2 id="text" ref={textRef} className="absolute text-center w-full">
          Carbon2Capital
        </h2>
        <img
          src="/images/background/leaf.png"
          ref={leafRef}
          id="leaf"
          alt="leaf"
          className="absolute"
        />
        <img
          src="/images/background/plant.png"
          id="plant"
          alt="plant"
          className="absolute"
        />
      </section>

      {/* Mission Statement */}
      {/* <div className="container mx-auto px-4"> */}
      <section className="sec">
        <h2 className="text-3xl font-bold mb-6">Grow Green, Invest Clean</h2>
        <p className="text-xl text-gray-600">
          Your daily choices matter more than you think. Join us in
          understanding how our everyday decisions shape the world we live in.
        </p>
      </section>
      {/* </div> */}

      {/* Interactive Game Section */}
      <InteractiveGame />
      <div id="carbon-footprint">
        <CarbonFootprint />
      </div>

      {/* The Bigger Picture Section */}
      <section className="pt-12 bg-white">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center mb-12">
            <h2 className="text-3xl font-bold">
              The Bigger Picture: Why Carbon Literacy Matters
            </h2>
            <p className="text-xl text-gray-600 mt-4">
              Every choice we make—what we eat, how we travel, where we
              invest—adds up. At Carbon2Capital, we believe knowledge is the
              first step to meaningful action. Together, we can shape a world
              where sustainability is second nature.
            </p>
          </div>
          {/* 3D Model Section */}
          <div className="w-full h-96">
            <EarthCanvas />
          </div>
          <div className="text-center mt-8">
            <Link
              to="/carbonliteracy"
              className="bg-[#359381] text-white px-6 py-2 rounded-lg font-medium hover:bg-[#003329] overflow-hidden transform transition-all duration-300 hover:scale-105 text-md sm:px-8"
            >
              Learn More About Carbon Literacy
            </Link>
          </div>
        </div>
      </section>

      {/* Understanding Through Stories */}
      <UnderstandingSection />

      {/* Partner With Us Section */}
      <section className="pt-8 pb-16 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">
            Partner With Us
          </h2>
          <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
            <div className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow">
              <h3 className="text-xl font-bold mb-4 text-[#359381]">
                For Banks
              </h3>
              <p className="text-gray-600 mb-4">
                Empower your customers with carbon tracking and green investment
                opportunities. Transform transaction data into sustainability
                insights.
              </p>
              <Link
                to="/solutions#banks"
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                className="text-[#359381] font-medium flex items-center hover:text-green-700 overflow-hidden transform transition-all duration-300 hover:scale-105"
              >
                Learn More <ArrowRight className="ml-2 h-4 w-4" />
              </Link>
            </div>
            <div className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow">
              <h3 className="text-xl font-bold mb-4 text-[#359381]">
                For Businesses
              </h3>
              <p className="text-gray-600 mb-4">
                Simplify your ESG reporting and carbon tracking. Get actionable
                strategies to reduce emissions and enhance sustainability.
              </p>
              <Link
                to="/solutions#businesses"
                onClick={() => {
                  setTimeout(() => {
                    const headerOffset =
                      document.querySelector(".fixed").offsetHeight;
                    const element = document.getElementById("businesses");
                    if (element) {
                      const elementPosition =
                        element.getBoundingClientRect().top + window.scrollY;
                      const offsetPosition = elementPosition - headerOffset;
                      window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth",
                      });
                    }
                  }, 100);
                }}
                className="text-[#359381] font-medium flex items-center hover:text-green-700 overflow-hidden transform transition-all duration-300 hover:scale-105"
              >
                Learn More <ArrowRight className="ml-2 h-4 w-4" />
              </Link>
            </div>
            <div className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow">
              <h3 className="text-xl font-bold mb-4 text-[#359381]">
                For Startups
              </h3>
              <p className="text-gray-600 mb-4">
                Build sustainability into your DNA. Access tools, education, and
                visibility to showcase your commitment to a greener future.
              </p>
              <Link
                to="/solutions#startups"
                onClick={() => {
                  setTimeout(() => {
                    const headerOffset =
                      document.querySelector(".fixed").offsetHeight;
                    const element = document.getElementById("startups");
                    if (element) {
                      const elementPosition =
                        element.getBoundingClientRect().top + window.scrollY;
                      const offsetPosition = elementPosition - headerOffset;
                      window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth",
                      });
                    }
                  }, 100);
                }}
                className="text-[#359381] font-medium flex items-center hover:text-green-700 overflow-hidden transform transition-all duration-300 hover:scale-105"
              >
                Learn More <ArrowRight className="ml-2 h-4 w-4" />
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-[#003329] text-white py-12">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-4">
            Ready to Discover Your Climate Story?
          </h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            Understanding your impact doesn't have to be complicated. Start with
            a simple bank statement, and we'll show you your personal climate
            journey.
          </p>

          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault(); // Prevent default link behavior
              const targetElement = document.getElementById("carbon-footprint");
              if (targetElement) {
                const headerOffset =
                  document.querySelector(".fixed").offsetHeight; // Get header height
                const elementPosition =
                  targetElement.getBoundingClientRect().top + window.scrollY;
                const offsetPosition = elementPosition - headerOffset;

                window.scrollTo({
                  top: offsetPosition,
                  behavior: "smooth",
                });
              }
            }}
            className="bg-white text-[#003329] px-8 py-3 rounded-lg font-medium hover:bg-[#359381] hover:text-white inline-block overflow-hidden transform transition-all duration-300 hover:scale-105"
          >
            Start Your Discovery
          </Link>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
