import React, { useState } from "react";
import axios from "axios";
import { collection, addDoc, getDocs, query, where } from "firebase/firestore";
import db from "../firebase";

const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

const BASE_URL = "/.netlify/functions";
// process.env.NODE_ENV === "production"
//   ? "/.netlify/functions"
//   : "http://localhost:5001";

const CarbonFootprint = () => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [emissionsData, setEmissionsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (!file) {
      alert("No file selected. Please upload a file.");
      return;
    }

    if (file.size === 0) {
      alert("The selected file is empty. Please choose a valid file.");
      return;
    }

    console.log("File selected:", file);
    setUploadedFile(file);
  };

  const processFile = async (base64EncodedFile, fileType) => {
    try {
      // Send the file to the backend /upload endpoint
      const { data: uploadResponse } = await axios.post(
        `${BASE_URL}/upload`,
        {
          file: base64EncodedFile,
          fileType,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Upload Response:", uploadResponse);

      // Properly structure the emissions data
      const emissions = {
        total: uploadResponse.emissions.total, // Using the total from your log
        byCategory: uploadResponse.emissions.byCategory,
        bySubCategory: uploadResponse.emissions.bySubCategory,
      };

      console.log("Emissions data being sent:", { emissions });

      // Send the data to the /generate-analogy endpoint
      const { data: insights } = await axios.post(
        `${BASE_URL}/generate-analogy`,
        { emissions, email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Insights Response:", insights);

      return {
        totalEmissions: Number(emissions.total).toFixed(2), // Ensure it's a number and format it
        categorizedTransactions: uploadResponse.categorizedTransactions,
        insights,
      };
    } catch (error) {
      console.error(
        "Process File Error:",
        error.response?.data || error.message
      );
      throw error;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email || !validateEmail(email)) {
      setEmailError(true);
      return;
    }

    if (!uploadedFile) {
      setUploadError(true);
      return;
    }

    setIsLoading(true);
    setErrorMessage(null);

    try {
      // Check if email already exists
      const emailQuery = query(
        collection(db, "emails"),
        where("email", "==", email)
      );
      const emailSnapshot = await getDocs(emailQuery);

      if (emailSnapshot.empty) {
        // Email doesn't exist, save it
        await addDoc(collection(db, "emails"), {
          email: email,
          timestamp: new Date(),
        });
        console.log("Email saved successfully!");
      } else {
        console.log("Email already exists, skipping save");
      }

      // Create a promise for file reading
      const fileReadPromise = new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          const base64EncodedFile = reader.result.split(",")[1];
          resolve(base64EncodedFile);
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(uploadedFile);
      });

      // Wait for file to be read
      const base64EncodedFile = await fileReadPromise;

      // Process the file and get results
      const results = await processFile(base64EncodedFile, uploadedFile.type);

      // Update emissions data
      setEmissionsData(results);
    } catch (error) {
      console.error("Error processing request:", error);

      // Extract error message from the response
      const backendError =
        error.response?.data?.error ||
        "Oops! Looks like our AI had a coffee break. 🤖 Let's give it another shot!";

      setErrorMessage(backendError);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-6">
          Calculate Your Carbon Footprint
        </h2>
        <p className="text-xl text-gray-600 text-center mb-8">
          Upload your bank statement to see your carbon impact and get
          personalized recommendations to reduce it.
        </p>
        <div className="max-w-2xl mx-auto bg-white p-6 rounded-xl shadow-sm">
          {!emissionsData ? (
            <form onSubmit={handleSubmit}>
              <label
                htmlFor="bank-statement-upload"
                className="block font-bold text-lg mb-4"
              >
                Upload Your Bank Statement (EXCEL/PDF)
              </label>
              <div
                className={`w-full border ${
                  uploadError ? "border-red-500" : "border-gray-400"
                } border-dashed p-6 rounded-lg mb-4 text-center bg-gray-50 hover:bg-gray-100 cursor-pointer ${
                  uploadError ? "animate-shake" : ""
                }`}
                onClick={() => {
                  if (uploadError) {
                    setUploadError(false); // Reset error state for animation restart
                    setTimeout(() => setUploadError(true), 10); // Re-trigger animation
                  }
                  document.getElementById("bank-statement-upload").click();
                }}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => {
                  e.preventDefault();
                  const file = e.dataTransfer.files[0];
                  if (file) {
                    setUploadedFile(file);
                    setUploadError(false); // Reset error when valid file is dropped
                  }
                }}
              >
                <input
                  type="file"
                  id="bank-statement-upload"
                  accept=".pdf, .xlsx, .xls, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={(e) => {
                    handleFileUpload(e);
                    setUploadError(false); // Reset error when a file is uploaded
                  }}
                  className="hidden"
                />
                {uploadedFile ? (
                  <div className="flex justify-between items-center">
                    <p className="text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer">
                      Selected: {uploadedFile.name}
                    </p>
                    <button
                      className="ml-2 text-red-500 hover:text-red-700"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the file input click
                        setUploadedFile(null); // Clear the uploaded file
                      }}
                    >
                      ✖
                    </button>
                  </div>
                ) : (
                  <p className="text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer">
                    Click or drag and drop your file here
                  </p>
                )}
              </div>

              <label htmlFor="email" className="block font-bold text-lg mb-2">
                Enter Your Email Address
              </label>
              <input
                type="email"
                id="email"
                placeholder="example@email.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`w-full border p-3 rounded-lg mb-4 ${
                  emailError && "border-red-500 animate-shake"
                }`}
                onAnimationEnd={() => setEmailError(false)} // Remove shake animation class after it ends
              />
              {errorMessage && (
                <div className="mb-6 text-center">
                  <p className="text-gray-800 mb-4">{errorMessage}</p>
                </div>
              )}
              <button
                type="submit"
                className="bg-[#359381] text-white px-6 py-3 rounded-lg font-medium hover:bg-[#003329] w-full flex justify-center items-center overflow-hidden transform transition-all duration-300 hover:scale-105"
                onClick={(e) => {
                  let preventSubmit = false;

                  if (!email || !validateEmail(email)) {
                    e.preventDefault(); // Prevent form submission
                    setEmailError(true); // Trigger the warning animation for email
                    preventSubmit = true;
                  }

                  if (!uploadedFile) {
                    e.preventDefault(); // Prevent form submission
                    setUploadError(true); // Trigger the warning animation for upload field
                    preventSubmit = true;
                  }

                  if (!preventSubmit) {
                    handleSubmit(e); // Call handleSubmit only if both fields are valid
                  }
                }}
              >
                {isLoading ? (
                  <>
                    <svg
                      className="animate-spin h-5 w-5 mr-3 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291a7.962 7.962 0 01-1.657-4.291H0c0 3.042 1.135 5.824 3 7.938l3-3.647z"
                      ></path>
                    </svg>
                    Processing...
                  </>
                ) : errorMessage ? (
                  "Try Again →"
                ) : (
                  "Analyze My Impact →"
                )}
              </button>
            </form>
          ) : (
            <div>
              <h3 className="text-2xl font-bold mb-4">
                Total Emissions:{" "}
                <span className="text-[#359381]">
                  {typeof emissionsData.totalEmissions === "number"
                    ? emissionsData.totalEmissions.toFixed(2)
                    : emissionsData.totalEmissions}{" "}
                  kg CO₂
                </span>
              </h3>

              {/* Only show category details if they exist */}
              {emissionsData.insights?.categoryDetails && (
                <div className="mb-4">
                  <p className="text-lg">
                    Highest impact category:{" "}
                    {emissionsData.insights.categoryDetails.mainCategory}
                  </p>
                  {emissionsData.insights.categoryDetails.topSubCategories
                    ?.length > 0 && (
                    <p className="text-gray-600">
                      Top areas:{" "}
                      {emissionsData.insights.categoryDetails.topSubCategories.join(
                        ", "
                      )}
                    </p>
                  )}
                </div>
              )}

              <p className="text-lg text-gray-600 mt-4">
                {emissionsData.insights?.analogy || "Analysis complete!"}
              </p>

              <div className="mt-8">
                <h3 className="text-xl font-bold mb-3">
                  Suggestions to Reduce Your Footprint
                </h3>
                {(emissionsData.insights?.tips || []).map((tip, index) => (
                  <div key={index} className="mb-2 flex items-start">
                    <span className="mr-2">•</span>
                    <span>{tip}</span>
                  </div>
                ))}
              </div>

              <div className="text-center mt-8">
                <button
                  onClick={() => setEmissionsData(null)}
                  className="bg-[#359381] text-white px-6 py-3 rounded-lg font-medium hover:bg-[#003329] overflow-hidden transform transition-all duration-300 hover:scale-105"
                >
                  Start Over
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="text-center mt-6">
          <p className="text-sm text-gray-500">
            *We value your privacy. Your data is securely encrypted and will not
            be shared.
          </p>
        </div>
      </div>
    </section>
  );
};

export default CarbonFootprint;
