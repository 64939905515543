// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBExIJOi7dNWGBqUarIuc0JaCeHFI9gIxQ",
  authDomain: "c2c-website-61a1b.firebaseapp.com",
  projectId: "c2c-website-61a1b",
  storageBucket: "c2c-website-61a1b.firebasestorage.app",
  messagingSenderId: "822577737786",
  appId: "1:822577737786:web:e0af4ebff059e146324503",
  measurementId: "G-8SCWV4VFL2",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
// Export Firestore
const db = getFirestore(app);
export default db;
