import React from "react";
import { Link } from "react-router-dom";
import {
  FacebookIcon,
  Twitter,
  InstagramIcon,
  LinkedinIcon,
  Mail,
  MapPin,
  Phone,
} from "lucide-react";

const Footer = () => {
  return (
    <footer className="bg-[#003329] text-white py-4 lg:py-8 mt-[0.25px]">
      <div className="container mx-auto px-5 lg:px-7.5 xl:px-10">
        <div className="grid grid-cols-1 lg:grid-cols-6 gap-8 lg:gap-12">
          {/* About Section - Full width on mobile, 2 columns on large screens */}
          <div className="space-y-4 lg:col-span-2">
            <h3 className="text-lg font-semibold">About Us</h3>
            <p className="text-sm text-gray-200">
              We help individuals, businesses, and startups make sustainability
              actionable and impactful. Join us in shaping a greener future!
            </p>
          </div>

          {/* Container for Quick Links and Contact - Side by side on mobile and large screens */}
          <div className="grid grid-cols-2 lg:col-span-4 gap-8 lg:gap-12">
            {/* Quick Links Section */}
            <div className="space-y-4">
              <h3 className="text-lg font-semibold">Quick Links</h3>
              <nav className="flex flex-col space-y-2">
                <Link
                  to="/"
                  className="text-gray-200 hover:text-[#359381] transition-colors duration-200"
                >
                  Home
                </Link>
                <Link
                  to="/solutions"
                  className="text-gray-200 hover:text-[#359381] transition-colors duration-200"
                >
                  Solutions
                </Link>
                <Link
                  to="/about-us"
                  className="text-gray-200 hover:text-[#359381] transition-colors duration-200"
                >
                  About Us
                </Link>
                <Link
                  to="/contact"
                  className="text-gray-200 hover:text-[#359381] transition-colors duration-200"
                >
                  Contact
                </Link>
              </nav>
            </div>

            {/* Contact Info Section */}
            <div className="space-y-4">
              <h3 className="text-lg font-semibold">Contact</h3>
              <ul className="space-y-3">
                <li className="flex items-center space-x-2 text-gray-200">
                  <MapPin className="w-4 h-4 text-[#359381]" />
                  <span className="text-xs">Dubai, UAE</span>
                </li>
                <li className="flex items-center space-x-2 text-gray-200">
                  <Phone className="w-4 h-4 text-[#359381]" />
                  <span className="text-xs">+971585510762</span>
                </li>
                <li className="flex items-center space-x-2 text-gray-200">
                  <Mail className="w-4 h-4 text-[#359381]" />
                  <span className="text-xs">info@carbon2capital.com</span>
                </li>
                <li className="flex items-center space-x-2 text-gray-200">
                  <div className="flex space-x-6">
                    <a
                      href="https://linkedin.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-200 hover:text-[#359381] transition-colors duration-200"
                    >
                      <LinkedinIcon className="w-5 h-5" />
                    </a>
                    <a
                      href="https://twitter.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-200 hover:text-[#359381] transition-colors duration-200"
                    >
                      <Twitter className="w-5 h-5" />
                    </a>
                    <a
                      href="https://instagram.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-200 hover:text-[#359381] transition-colors duration-200"
                    >
                      <InstagramIcon className="w-5 h-5" />
                    </a>
                    <a
                      href="https://facebook.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-200 hover:text-[#359381] transition-colors duration-200"
                    >
                      <FacebookIcon className="w-5 h-5" />
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Social Media and Bottom Section */}
        <div className="mt-8 lg:mt-12 pt-6 border-t border-gray-200/10">
          <div className="space-y-4 md:space-y-0">
            <p className="text-sm text-gray-200 text-center">
              © {new Date().getFullYear()} Carbon2Capital. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
