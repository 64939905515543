import React, { useState } from "react";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [formStatus, setFormStatus] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormStatus("Submitting...");
    try {
      // Add your API integration logic or backend call here
      console.log("Form submitted with data:", formData);
      setFormStatus("Thank you for reaching out! We'll get back to you soon.");
      setFormData({
        name: "",
        email: "",
        message: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      setFormStatus("Something went wrong. Please try again later.");
    }
  };

  return (
    <section className="pt-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold bg-gradient-to-r from-[#359381] to-[#359381] bg-clip-text text-transparent mb-6 text-center">
          Contact Us
        </h2>
        <p className="text-lg text-gray-600 text-center mb-8">
          Got questions or feedback? We'd love to hear from you!
        </p>
        <div className="max-w-2xl mx-auto bg-white p-6 rounded-xl shadow-sm">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="name" className="block font-bold text-lg mb-2">
                Your Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full border p-3 rounded-lg"
                placeholder="Enter your name"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block font-bold text-lg mb-2">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full border p-3 rounded-lg"
                placeholder="Enter your email"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="message" className="block font-bold text-lg mb-2">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                className="w-full border p-3 rounded-lg"
                placeholder="Write your message here"
                rows="5"
              ></textarea>
            </div>
            <button
              type="submit"
              className="bg-[#359381] text-white px-6 py-3 rounded-lg font-medium hover:bg-[#003329] w-full overflow-hidden transform transition-all duration-300 hover:scale-105"
            >
              Send Message
            </button>
          </form>
          {formStatus && (
            <p className="mt-4 text-center text-sm text-gray-600">
              {formStatus}
            </p>
          )}
        </div>
      </div>
      {/* FAQ Section */}
      <section className="pb-12 pt-12 bg-white mt-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-8">
            Frequently Asked Questions
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div>
              <h3 className="font-bold text-xl mb-2">
                How does Carbon2Capital calculate emissions?
              </h3>
              <p className="text-gray-600">
                We use internationally recognized IPCC guidelines to convert
                your financial transactions into carbon emission data.
              </p>
            </div>
            <div>
              <h3 className="font-bold text-xl mb-2">How secure is my data?</h3>
              <p className="text-gray-600">
                Your privacy is our priority. All data is encrypted, and we
                follow industry-leading security standards.
              </p>
            </div>
            <div>
              <h3 className="font-bold text-xl mb-2">
                Can I invest in projects through the platform?
              </h3>
              <p className="text-gray-600">
                Yes! We connect you to vetted green projects so you can offset
                your emissions while earning a return.
              </p>
            </div>
            <div>
              <h3 className="font-bold text-xl mb-2">Is this platform free?</h3>
              <p className="text-gray-600">
                Basic features are free. Advanced features like investment
                tracking require a small subscription fee.
              </p>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Contact;
