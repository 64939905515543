import React, { useState } from "react";
import { motion } from "framer-motion";
import { Check, X, RotateCcw } from "lucide-react";

const quizQuestions = [
  {
    id: 1,
    question: "Which sector contributes the most to global carbon emissions?",
    options: [
      "Transportation",
      "Electricity and Heat Production",
      "Agriculture",
      "Manufacturing",
    ],
    correctAnswer: 1,
    explanation:
      "Electricity and heat production account for approximately 31% of global emissions, making it the largest contributor to greenhouse gas emissions.",
  },
  {
    id: 2,
    question:
      "How much CO₂ does a typical passenger vehicle emit per kilometer?",
    options: ["50 grams", "120 grams", "200 grams", "300 grams"],
    correctAnswer: 1,
    explanation:
      "On average, a typical passenger vehicle emits about 120 grams of CO₂ per kilometer driven.",
  },
  {
    id: 3,
    question:
      "What percentage of global greenhouse gas emissions come from food waste?",
    options: ["2-3%", "4-5%", "6-8%", "8-10%"],
    correctAnswer: 2,
    explanation:
      "Food waste contributes to 6-8% of global greenhouse gas emissions, equivalent to about 3.3 billion tonnes of CO₂ annually.",
  },
  {
    id: 4,
    question:
      "How much can switching to LED bulbs reduce lighting energy consumption?",
    options: ["25-30%", "40-50%", "60-70%", "75-80%"],
    correctAnswer: 3,
    explanation:
      "LED bulbs can reduce energy consumption by 75-80% compared to traditional incandescent bulbs, making them highly efficient.",
  },
  {
    id: 5,
    question: "What is the carbon footprint of producing 1kg of beef?",
    options: ["10-15 kg CO₂", "20-25 kg CO₂", "30-35 kg CO₂", "60-65 kg CO₂"],
    correctAnswer: 3,
    explanation:
      "Producing 1kg of beef generates approximately 60-65 kg of CO₂ equivalent, making it one of the most carbon-intensive foods.",
  },
  {
    id: 6,
    question: "How much carbon can a mature tree absorb per year?",
    options: ["5-10 kg", "20-25 kg", "30-35 kg", "40-45 kg"],
    correctAnswer: 1,
    explanation:
      "A mature tree absorbs about 20-25 kg of CO₂ per year, highlighting the importance of forest preservation.",
  },
];

const CarbonQuiz = () => {
  const [currentQuestions, setCurrentQuestions] = useState(() =>
    [...quizQuestions].sort(() => 0.5 - Math.random()).slice(0, 4)
  );
  const [userAnswers, setUserAnswers] = useState({});
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);

  const handleAnswer = (questionId, answerIndex) => {
    setUserAnswers((prev) => ({
      ...prev,
      [questionId]: answerIndex,
    }));
  };

  const calculateScore = () => {
    let correct = 0;
    currentQuestions.forEach((question) => {
      if (userAnswers[question.id] === question.correctAnswer) {
        correct += 1;
      }
    });
    return (correct / currentQuestions.length) * 100;
  };

  const handleSubmit = () => {
    const finalScore = calculateScore();
    setScore(finalScore);
    setShowResults(true);
  };

  const resetQuiz = () => {
    setCurrentQuestions(
      [...quizQuestions].sort(() => 0.5 - Math.random()).slice(0, 4)
    );
    setUserAnswers({});
    setShowResults(false);
    setScore(0);
  };

  return (
    <div className="bg-gradient-to-br from-teal-100 to-white p-8 rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-center mb-6">
        Test Your Carbon Knowledge
      </h2>

      <div className="space-y-8">
        {currentQuestions.map((question, index) => (
          <motion.div
            key={question.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className="bg-white p-6 rounded-lg shadow-md"
          >
            <h3 className="text-md font-semibold mb-2">
              {index + 1}. {question.question}
            </h3>

            <div className="space-y-2">
              {question.options.map((option, optionIndex) => (
                <button
                  key={optionIndex}
                  onClick={() =>
                    !showResults && handleAnswer(question.id, optionIndex)
                  }
                  className={`w-full p-2 text-left rounded-lg transition-all ${
                    showResults
                      ? optionIndex === question.correctAnswer
                        ? "bg-green-100 border-green-500"
                        : userAnswers[question.id] === optionIndex
                        ? "bg-red-100 border-red-500"
                        : "bg-gray-50"
                      : userAnswers[question.id] === optionIndex
                      ? "bg-teal-100 border-teal-500"
                      : "bg-gray-50 hover:bg-gray-100"
                  } border-2 ${
                    userAnswers[question.id] === optionIndex
                      ? "border-teal-500"
                      : "border-transparent"
                  }`}
                >
                  {option}
                  {showResults && optionIndex === question.correctAnswer && (
                    <Check className="inline ml-2 text-green-500" />
                  )}
                  {showResults &&
                    userAnswers[question.id] === optionIndex &&
                    optionIndex !== question.correctAnswer && (
                      <X className="inline ml-2 text-red-500" />
                    )}
                </button>
              ))}
            </div>

            {showResults && (
              <div className="mt-4 p-4 bg-blue-50 rounded-lg">
                <p className="text-sm text-blue-800">
                  <strong>Explanation:</strong> {question.explanation}
                </p>
              </div>
            )}
          </motion.div>
        ))}
      </div>

      <div className="mt-8 text-center">
        {!showResults ? (
          <button
            onClick={handleSubmit}
            disabled={
              Object.keys(userAnswers).length !== currentQuestions.length
            }
            className={`px-8 py-3 rounded-lg font-semibold transition-all ${
              Object.keys(userAnswers).length === currentQuestions.length
                ? "bg-[#359381] text-white hover:bg-[#003329]"
                : "bg-gray-300 text-gray-500 cursor-not-allowed"
            }`}
          >
            Submit Answers
          </button>
        ) : (
          <div className="space-y-4">
            <div className="text-2xl font-bold">
              Your Score: {score.toFixed(0)}%
            </div>
            <button
              onClick={resetQuiz}
              className="px-8 py-3 rounded-lg font-semibold bg-[#359381] text-white hover:bg-[#003329] transition-all flex items-center justify-center mx-auto"
            >
              <RotateCcw className="w-4 h-4 mr-2" />
              Try Again
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CarbonQuiz;
